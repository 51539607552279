var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row mt-5 w-85 big-div",staticStyle:{"position":"relative","z-index":"100"},style:(_vm.getPageDesign() +
        `zoom:${this.pageZoomValue ? this.pageZoomValue : 90}%;`)},[(_vm.getImageAlign == 'no')?_c('div',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),staticClass:"bg",style:(_vm.getbackgroundImage(
            _vm.IsImageAdded,
            _vm.pageDesign.imageBlock,
            _vm.getImageAlign,
            _vm.isDesktopView
          ) + _vm.getBgDivStyles())}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftImage),expression:"showLeftImage"}],staticClass:"col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0",class:{
          'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
          'image-division': _vm.isDesktopView,
        },style:(_vm.calculateImageWidth(
            _vm.pageDesign.imageBlock,
            _vm.isDesktopView,
            _vm.IsImageVariableWidthActive
          ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : '',style:(_vm.getImageOpacity(_vm.pageDesign.imageBlock.style)),on:{"click":_vm.activeImageCustomize}},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),class:[
                `pic-${_vm.getSelectedPage.order}`,
                _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) ==
                '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ],style:(_vm.getUniversalImageStyle(
                  _vm.isDesktopView,
                  _vm.pageDesign.imageBlock.style,
                  _vm.IsImageVariableWidthActive,
                  _vm.pageDesign.imageBlock
                ) + _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView)),"alt":""}})],1)]),_c('button',{staticClass:"hide close-icon",attrs:{"id":"clear"},on:{"click":_vm.deleteImage}},[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z","fill":"black","fill-opacity":"0.5"}}),_c('path',{attrs:{"d":"M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z","fill":"white"}})])])]),_c('div',{staticClass:"",class:!_vm.IsImageAdded ? 'col-12' : _vm.imageAddedLayout,style:(_vm.getMainPaddingCss(_vm.pageDesign.pageStyles, _vm.isDesktopView) +
          _vm.getScrollCSS(
            _vm.pageDesign.pageStyles,
            _vm.isDesktopView,
            _vm.pageDesign.imageBlock.style,
            _vm.pageDesign.pageType
          ) +
          _vm.calculateContentWidth(
            _vm.pageDesign.imageBlock,
            _vm.isDesktopView,
            _vm.IsImageVariableWidthActive,
            _vm.pageDesign.pageStyles
          ))},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"flex-end","height":"100%"}},[_c('div',{staticClass:"h-100",staticStyle:{"overflow":"hidden scroll"}},[_c('div',[_c('ProgressBar',{attrs:{"order":_vm.CurrentPage.order}})],1),_c('draggable',_vm._b({staticClass:"dragArea list-group",staticStyle:{"cursor":"move"},attrs:{"group":"actions","chosen-class":"chosen","drag-class":"drag"},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false},"change":_vm.handleDraggableElementDrop},model:{value:(_vm.pageDesignBlocks),callback:function ($$v) {_vm.pageDesignBlocks=$$v},expression:"pageDesignBlocks"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.pageDesignBlocks),function(pro,ind){return _c('div',{key:`${pro.type}-${ind}`,class:(_vm.dragging && pro.type != 'button') ? 'dragElementBorder' : null,attrs:{"draggable":"false"},on:{"click":function($event){return _vm.changeCustomize(ind)}}},[_c('div',{class:pro.type !== 'button' ? 'designBlockElement':null,staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style) + _vm.getDivBorderCss(pro)),attrs:{"id":`${pro.type}-${ind}`}},[(pro.customizeStatus)?_c('EditorAction',{attrs:{"deleteCondition":_vm.getDeleteButtonCondition(pro),"currElementIndex":ind,"currElementType":pro.type}}):_vm._e(),(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
                      _vm.getPaddingCss(pro.style) +
                      _vm.getMarginCss(pro.style, _vm.isDesktopView) +
                      _vm.getColorWithOpacity(pro.style) +
                      _vm.getTextRotate(pro.style) +
                      _vm.getLineHeight(pro.style) +
                      _vm.getTextAlign(pro.style) +
                      _vm.getFontStyle(pro.style,_vm.isDesktopView)),domProps:{"innerHTML":_vm._s(_vm.getEditorPersonalisedValueStyle(pro.content))}}):_vm._e(),(pro.type == 'html')?_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
                      _vm.getColorWithOpacity(pro.style) +
                      _vm.getTextRotate(pro.style) +
                      _vm.getTextAlign(pro.style))},[_c('HtmlElement',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e(),(pro.type == 'option')?_c('div',[_c('div',[(_vm.radioInputEditMode)?_c('span',{staticClass:"m-2 d-inline-block",staticStyle:{"cursor":"pointer"}},[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z","fill":"black","fill-opacity":"0.5"}}),_c('path',{attrs:{"d":"M10.2858 23.2593C10.2858 24.4 11.1429 25.3333 12.1905 25.3333H19.8096C20.8572 25.3333 21.7143 24.4 21.7143 23.2593V10.8148H10.2858V23.2593ZM12.1905 12.8889H19.8096V23.2593H12.1905V12.8889ZM19.3334 7.70369L18.381 6.66666H13.6191L12.6667 7.70369H9.33337V9.77777H22.6667V7.70369H19.3334Z","fill":"white"}})])]):_vm._e(),_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
                          _vm.getTextAlign(pro.style))},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getCurrentSelectedOptionType === 'text'),expression:"getCurrentSelectedOptionType === 'text'"}]},[(pro.style.textAlign != 'end')?_c('div',{class:_vm.getOptionAlignment == 'vertical'
                                ? 'd-flex flex-column '
                                : 'd-flex  flex-wrap ',style:(_vm.getOptionAlignment == 'vertical'
                                ? null
                                : ` justify-content:${pro.style.textAlign}; `)},_vm._l((_vm.getOptionsArr),function(radio,index){return _c('div',{key:index,staticClass:"d-flex align-items-start",class:[
                                _vm.getOptionAlignment == 'horizontal'
                                  ? ' mr-2'
                                  : 'mb-1',
                                _vm.QuestionPageType == 'singleAnswer'
                                  ? 'radioboxDiv'
                                  : ' form-check checkboxDiv',
                              ],style:(_vm.QuestionPageType == 'singleAnswer'
                                  ? _vm.selectedCheckboxColor(pro.style)
                                  : _vm.selectedCheckboxColor(pro.style))},[_c('div',{staticClass:"d-flex align-items-center w-100",style:(`justify-content:${pro.style.textAlign};`)},[_c('input',{class:_vm.QuestionPageType == 'singleAnswer'
                                      ? 'mr-2 '
                                      : 'form-check-input',style:({
                                    height: pro.style.fontSize + 'px',
                                    width: pro.style.fontSize + 'px',
                                    position: 'relative',
                                  }),attrs:{"type":_vm.QuestionPageType == 'singleAnswer'
                                      ? 'radio'
                                      : 'checkbox',"name":"Inputs","id":`${_vm.CurrentPage.order}-${index}`},domProps:{"value":_vm.QuestionPageType == 'singleAnswer'
                                      ? `${radio.value}`
                                      : `${radio}`}}),_c('label',{staticClass:"m-0",class:_vm.QuestionPageType == 'singleAnswer'
                                      ? ''
                                      : 'form-check-label',style:(_vm.getColorCss(pro.style) +
                                    _vm.getColorWithOpacity(pro.style) +
                                    _vm.getFontStyle(pro.style)),attrs:{"for":`${_vm.CurrentPage.order}-${index}`}},[_c('span',{domProps:{"innerHTML":_vm._s(radio.value)}})])])])}),0):_c('div',{class:_vm.getOptionAlignment == 'vertical'
                                ? 'd-flex flex-column '
                                : 'd-flex  flex-wrap ',style:(_vm.getOptionAlignment == 'vertical'
                                ? null
                                : ` justify-content:${pro.style.textAlign}; `)},_vm._l((_vm.getOptionsArr),function(radio,index){return _c('div',{key:index,staticClass:"d-flex align-items-start",class:[
                                _vm.getOptionAlignment == 'horizontal'
                                  ? 'mr-2'
                                  : 'mb-1',
                                _vm.QuestionPageType == 'singleAnswer'
                                  ? 'radioboxDiv'
                                  : ' form-check checkboxDiv',
                              ],style:(_vm.QuestionPageType == 'singleAnswer'
                                  ? _vm.selectedCheckboxColor(pro.style)
                                  : _vm.selectedCheckboxColor(pro.style))},[_c('div',{staticClass:"d-flex align-items-center w-100",style:(`justify-content:${pro.style.textAlign};`)},[_c('label',{staticClass:"m-0",class:_vm.QuestionPageType == 'singleAnswer'
                                      ? 'mr-2 '
                                      : 'form-check-label mr-4',style:(_vm.getColorCss(pro.style) +
                                    _vm.getColorWithOpacity(pro.style) +
                                    _vm.getFontStyle(pro.style)),attrs:{"for":`${_vm.CurrentPage.order}-${index}-right`}},[_c('span',{domProps:{"innerHTML":_vm._s(radio.value)}})]),_c('input',{class:_vm.QuestionPageType == 'singleAnswer'
                                      ? ''
                                      : 'form-check-input',style:({
                                    height: pro.style.fontSize + 'px',
                                    width: pro.style.fontSize + 'px',
                                    position: 'relative',
                                  }),attrs:{"type":_vm.QuestionPageType == 'singleAnswer'
                                      ? 'radio'
                                      : 'checkbox',"name":"Inputs","id":`${_vm.CurrentPage.order}-${index}-right`},domProps:{"value":_vm.QuestionPageType == 'singleAnswer'
                                      ? `${radio.value}`
                                      : `${radio}`}})])])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getCurrentSelectedOptionType === 'image'),expression:"getCurrentSelectedOptionType === 'image'"}]},[_c('div',{staticClass:"row m-0"},_vm._l((_vm.getOptionsArr),function(radio,index){return _c('div',{key:index,staticClass:"d-flex flex-column",class:_vm.isDesktopView
                                  ? `col-${12 / pro.OptionsPerRow}`
                                  : `col-${12 / pro.OptionsPerMobile}`,style:(_vm.getDivAlignmentStyle(pro.style.textAlign) +
                                _vm.getSpaceBetweenRow(pro.style))},[_c('div',{staticClass:"ImageInputBlock position-relative",style:(_vm.getImageHeightWidth(
                                    pro.style,
                                    _vm.isDesktopView
                                  ) +
                                  `${
                                    _vm.IsSelectedImageExist(radio)
                                      ? `border:2px solid ${
                                          pro.style.selectedImageIconColor ||
                                          '#000000'
                                        };padding:2px;`
                                      : null
                                  }`),on:{"click":function($event){return _vm.checkSelectedImage(radio)}}},[_c('LazyImage',{key:_vm.ImageSrc(radio.image) ||
                                    '/images/image_placeholder.png' + index,staticStyle:{"cursor":"pointer","height":"100%","width":"100%"},style:(_vm.getBorderRadius(pro.style)),attrs:{"src":_vm.ImageSrc(radio.image) ||
                                    `/images/image_placeholder.png`,"alt":""}})],1),_c('div',[_c('p',{staticClass:"m-0 p-0 mt-2",style:(_vm.getColorCss(pro.style) +
                                    _vm.getColorWithOpacity(pro.style) +
                                    _vm.getFontStyle(pro.style)),domProps:{"innerHTML":_vm._s(radio.value)}})])])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getCurrentSelectedOptionType === 'tab'),expression:"getCurrentSelectedOptionType === 'tab'"}]},[(_vm.getOptionAlignment == 'vertical')?_c('div',[_c('ul',{staticClass:"m-0 p-0",style:(`max-width: ${_vm.isDesktopView ?  pro.style.defaultTab.maxTabWidthDesktop || 500 : pro.style.defaultTab.maxTabWidthMobile || 500 }px;
                                list-style: none;
                                display: inline-block;`)},_vm._l((_vm.getOptionsArr),function(radio,index){return _c('li',{key:index,staticClass:"mb-2",class:_vm.isDesktopView ? 'Tab' : '',style:(`min-width:${ _vm.isDesktopView ?  pro.style.defaultTab.minTabWidthDesktop || 200 : pro.style.defaultTab.minTabWidthMobile || 200 }px; max-width: ${_vm.isDesktopView ?  pro.style.defaultTab.maxTabWidthDesktop || 500 : pro.style.defaultTab.maxTabWidthMobile || 500 }px;`)},[_c('div',[(_vm.QuestionPageType == 'singleAnswer')?_c('button',{staticClass:"btn tab-btn border w-100",style:(_vm.SelectedValue == radio.value
                                        ? _vm.getSelectedTabStyles(
                                            pro.style.selectedTab,
                                            pro.style
                                          )
                                        : _vm.getDefaultTabStyles(
                                            pro.style.defaultTab,
                                            pro.style
                                          )),on:{"click":function($event){return _vm.updateSelectedAnswerRadio(radio.value)}}},[_c('span',{domProps:{"innerHTML":_vm._s(radio.value)}})]):_c('button',{staticClass:"btn tab-btn border w-100",style:(_vm.isSelectedOptionExist(radio)
                                        ? _vm.getSelectedTabStyles(
                                            pro.style.selectedTab,
                                            pro.style
                                          )
                                        : _vm.getDefaultTabStyles(
                                            pro.style.defaultTab,
                                            pro.style
                                          )),on:{"click":function($event){return _vm.updateSelectedAnswer(radio)}}},[_c('span',{domProps:{"innerHTML":_vm._s(radio.value)}})])])])}),0)]):_c('div',{},[_c('div',{staticClass:"m-0 p-0 row",staticStyle:{"display":"flex","flex-wrap":"wrap"},style:(`height:100%;max-width: 100%; justify-content:${pro.style.textAlign};`)},_vm._l((_vm.getOptionsArr),function(radio,index){return _c('div',{key:index,staticClass:"mb-2",class:[
                                  _vm.isDesktopView ? 'Tab' : '',
                                  _vm.isDesktopView
                                    ? pro.optionSetting.horizontalOptionsPerRow
                                      ? `col-${
                                          12 /
                                          pro.optionSetting
                                            .horizontalOptionsPerRow
                                        }`
                                      : 'col-6 col-sm-6 col-md-4'
                                    : pro.optionSetting
                                        .horizontalOptionsPerRowMobile
                                    ? `col-${
                                        12 /
                                        pro.optionSetting
                                          .horizontalOptionsPerRowMobile
                                      }`
                                    : 'col-6 col-sm-6 col-md-4',
                                ],staticStyle:{"flex-grow":"1"}},[_c('div',{staticStyle:{"height":"100% !important"}},[(_vm.QuestionPageType == 'singleAnswer')?_c('button',{staticClass:"btn tab-btn border w-100",staticStyle:{"height":"100% !important"},style:(_vm.SelectedValue == radio.value
                                        ? _vm.getSelectedTabStyles(
                                            pro.style.selectedTab,
                                            pro.style
                                          )
                                        : _vm.getDefaultTabStyles(
                                            pro.style.defaultTab,
                                            pro.style
                                          )),on:{"click":function($event){return _vm.updateSelectedAnswerRadio(radio.value)}}},[_c('span',{domProps:{"innerHTML":_vm._s(radio.value)}})]):_c('button',{staticClass:"btn tab-btn border w-100",staticStyle:{"height":"100% !important"},style:(_vm.isSelectedOptionExist(radio)
                                        ? _vm.getSelectedTabStyles(
                                            pro.style.selectedTab,
                                            pro.style
                                          )
                                        : _vm.getDefaultTabStyles(
                                            pro.style.defaultTab,
                                            pro.style
                                          )),on:{"click":function($event){return _vm.updateSelectedAnswer(radio)}}},[_c('span',{domProps:{"innerHTML":_vm._s(radio.value)}})])])])}),0)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getCurrentSelectedOptionType === 'dropdown'),expression:"getCurrentSelectedOptionType === 'dropdown'"}]},[_c('div',{staticClass:"select-div h-100"},[_c('multiselect',{staticClass:"multiSelect",style:(_vm.getColorCss(pro.style) +
                                _vm.getColorWithOpacity(pro.style) +
                                _vm.getFontStyle(pro.style) +
                                _vm.QuestionPageStyle(pro.style)),attrs:{"options":_vm.getOptionsArr,"multiple":false,"placeholder":"Select an option","close-on-select":false,"searchable":false,"label":"value","track-by":"value"},model:{value:(_vm.SelectedValue),callback:function ($$v) {_vm.SelectedValue=$$v},expression:"SelectedValue"}})],1)])])])]):_vm._e(),(pro.type == 'tooltip')?_c('div',{style:('display:flex;justify-content:' + pro.style.position + ';')},[_c('button',{style:(_vm.getButtonCss(pro.style, _vm.isDesktopView) +
                        _vm.getCircularBtn(pro.isBtnRounded)),on:{"mouseenter":function($event){pro.isTooltipVisible = true},"mouseleave":function($event){pro.isTooltipVisible = false}}},[(pro.useIcon)?_c('span',[_c('BIcon',{attrs:{"icon":pro.selectedIcon}})],1):_c('span',{style:({
                          opacity: `${pro.style.textOpacity}%`,
                          fontSize: `${pro.style.fontSize}px`,
                        })},[_vm._v(" "+_vm._s(pro.text)+" ")])]),(pro.isTooltipVisible)?_c('ToolTipComp',{attrs:{"tooltipData":pro,"isDesktopView":_vm.isDesktopView}}):_vm._e()],1):_vm._e(),(pro.type == 'imageComp')?_c('div',[_c('InlineImageComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e()],1)])}),0)],1),_c('div',[_c('div',{on:{"click":function($event){return _vm.changeStaticBtnCustomisation(_vm.getButtonIndex)}}},[_c('div',{staticClass:"designBlockElement",style:(_vm.getHeightWidth(_vm.StaticButtonElement.style) +
                  _vm.getStaticButtonDivCss(_vm.staticButtonStyles))},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex",style:(_vm.getBackNextButtonPosition(
                        _vm.StaticButtonElement,
                        _vm.CurrentPage.value
                      ))},[(
                        !_vm.StaticButtonElement.content.backButton.hideBackButton
                      )?_c('button',{style:(_vm.getCommonButtonCSS(
                          _vm.StaticButtonElement.style,
                          _vm.isDesktopView
                        ) +
                        _vm.getBackNextButtonCss(
                          _vm.StaticButtonElement.content.backButton.style,_vm.isDesktopView
                        ))},[_c('span',{style:({
                          opacity: `${_vm.StaticButtonElement.content.backButton.style.textOpacity}%`,
                          fontSize: `${_vm.StaticButtonElement.content.backButton.style.fontSize}px`,
                        })},[_vm._v(_vm._s(_vm.StaticButtonElement.content.backButton.text))])]):_vm._e(),(
                        !_vm.StaticButtonElement.content.nextButton.hideNextButton
                      )?_c('button',{style:(_vm.getCommonButtonCSS(
                          _vm.StaticButtonElement.style,
                          _vm.isDesktopView
                        ) +
                        _vm.getBackNextButtonCss(
                          _vm.StaticButtonElement.content.nextButton.style,_vm.isDesktopView
                        ))},[_c('span',{style:({
                          opacity: `${_vm.StaticButtonElement.content.nextButton.style.textOpacity}%`,
                          fontSize: `${_vm.StaticButtonElement.content.nextButton.style.fontSize}px`,
                        })},[_vm._v(_vm._s(_vm.StaticButtonElement.content.nextButton.text))])]):_vm._e()])])])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightImage),expression:"showRightImage"}],staticClass:"col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0",class:{
          'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
          'image-division': _vm.isDesktopView,
        },style:(_vm.calculateImageWidth(
            _vm.pageDesign.imageBlock,
            _vm.isDesktopView,
            _vm.IsImageVariableWidthActive
          ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : '',on:{"click":_vm.activeImageCustomize}},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),class:[
                `pic-${_vm.getSelectedPage.order}`,
                _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) ==
                '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ],style:(_vm.getUniversalImageStyle(
                  _vm.isDesktopView,
                  _vm.pageDesign.imageBlock.style,
                  _vm.IsImageVariableWidthActive,
                  _vm.pageDesign.imageBlock
                ) +
                _vm.getImageOpacity(_vm.pageDesign.imageBlock.style) +
                _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView)),"alt":""}})],1)]),_c('button',{staticClass:"hide close-icon",attrs:{"id":"clear"},on:{"click":_vm.deleteImage}},[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z","fill":"black","fill-opacity":"0.5"}}),_c('path',{attrs:{"d":"M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z","fill":"white"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }